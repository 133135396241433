import {roundDecimal} from "./general_functions";

// https://learn.microsoft.com/en-us/azure/static-web-apps/database-azure-sql?tabs=bash&pivots=static-web-apps-rest
async function UpdateUserLastLoginTime(userID) {
    const currentTimestamp = new Date().toLocaleString('en-US');
    const gql = {
        query: `mutation update($user_id: String!, $item: Updateuser_last_accessedInput!) {
            updateuser_last_accessed(user_id: $user_id, item: $item) {
                user_id
                last_accessed
            }
        }`,
        variables: {
            user_id: userID,
            item: {last_accessed: currentTimestamp}
        } 
    };

    await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    });

}


// We need this for async react
async function GetUserLastLoginTime(userID) {
    const gql = {
        query: `query GetUserLastLoginTime($userID: String!){
            user_last_accesseds(filter: {user_id: {eq: $userID}})
            {
                items{
                   last_accessed  
                }
            }
        }`,
        variables: {
            userID: userID
        } 
    };

    const lastAccessed = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    }).then((res) => res.json()).then((res) => res.data.user_last_accesseds.items);

    if (lastAccessed.length === 1) {
        return lastAccessed[0]['last_accessed'];
    }

    // if there is no entry for a user we want to create one for the future
    await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/user_last_accessed/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({user_id: userID})
    });

    return undefined;
}


async function GetUserHomeData(UID, userGroups) {
    // Let them run at the same time
    const [userData, lastAccessed, appData] = await Promise.all([GetUserDataByUID(UID), GetUserLastLoginTime(UID), GetAppDataFromGroupNames(userGroups)]);

    // User not found
    if (userData === -1) {return -1}

    // There isnt really a need for the await but what evs
    await UpdateUserLastLoginTime(UID);
    
    // Join everything together
    return {...userData, lastAccessed, apps:appData};
}


// We need this for async react
async function GetAppDataFromGroupNames(userGroups) {
    // The everyone role does not matter or correlate to a page
    userGroups = userGroups.filter(group => group !== 'Everyone');
    // Create the filters
    userGroups = userGroups.map((group) => `{app_name: {eq: "${group}"}}`);
    
    const gql = {
        query: `query GetAppDataFromGroupNames{
            apps(filter: {or: [${userGroups.join(', ')}]})
            {
                items{
                    app_name,
                    app_img_link
                }
            }
        }`
    };

    const appData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    }).then((res) => res.json()).then((res) => res.data.apps.items);

    return appData;
}


// We need this for async react
async function GetUserDataByUID(UID) {
    const gql = {
        query: `query GetUserDataByUID($UID: String!){
            users(filter: {user_id: {eq: $UID}})
            {
                items{
                    user_id,
                    first_name,
                    last_name
                }
            }
        }`,
        variables: {
            UID: UID
        } 
    };

    const userData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    }).then((res) => res.json()).then((res) => res.data.users.items);

    // Ideally userData.length === 0 is the only condition that will hit
    if (!userData || userData.length === 0 || (userData.length === 1 && !userData[0]))
    {
        return -1;
    }
    // This should have only one user in it
    else if (userData.length === 1 && userData[0])
    {
        return {
            userID: userData[0].user_id,
            firstName: userData[0].first_name,
            lastName: userData[0].last_name
        };
    }
    // This should never happen since we have the unique constraint in sql
    else if (userData.length > 1) {
        throw new Error('ERROR FETCHING USER INFO, MULTIPLE USERS WITH SAME UID')
    }
}


// We need this for async react
async function GetBillsByUserID(UserID) {
    const gql = {
        query: `query GetBillsByUID($UserID: String!){
            users(filter: {user_id: {eq: $UserID}})
            {
                items{
                    user_bills(filter: {bills : {current_row_ind: {eq: true}}}) {
                        items{
                            split_type,
                            split_value,
                            bill_desc
                            bills(filter: {current_row_ind: {eq: true}}){
                                items{
                                    bill_row_id,
                                    bill_id,
                                    balance,
                                    msg_ts,
                                    load_ts
                                }
                            }
                        }
                    }
                }
            }
        }`,
        variables: {
            UserID: UserID
        } 
    };

    const userData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    }).then((res) => res.json()).then((res) => res.data.users.items);
    
    // No user was found with id, this should not happen since this is already checked in home page
    if (!userData) {
        return;
    } else if (userData.length === 0) {
        return [];
    } else {
        let billsLst = [];
        for (let i = 0; i < userData[0].user_bills.items.length; i++) {
            let currBillType = userData[0].user_bills.items[i];
            for (let j = 0; j < currBillType.bills.items.length; j++) {
                let bill = currBillType.bills.items[j];

                // Add the bill desc
                bill.bill_desc = currBillType.bill_desc;

                // There is a split for this bill
                if(currBillType.split_type != null & currBillType.split_value != null) {
                    const split_type = currBillType.split_type.toUpperCase();
                    // Specified dollar amount
                    if (split_type === 'D') {
                        bill.balance = currBillType.split_value;     
                    // Specified percent of bill
                    } else if (split_type === 'P') {
                        bill.balance = roundDecimal(bill.balance * (currBillType.split_value / 100));
                    }
                }
                billsLst.push(bill);
              }
        }
        
        // Sort by the date they were loaded
        billsLst = billsLst.sort(function(a, b) {
            if (a.msg_ts < b.msg_ts) {
                return 1
            }
            else if (a.msg_ts > b.msg_ts) {
                return -1
            }
            return 0
        });

        return billsLst;
    }
}


async function UpdatePaidBills(billData) {
    console.log(billData);

    billData.forEach(bill => {
        const gql = {
            query: `mutation update($bill_row_id: Int!, $item: UpdatebillsInput!) {
                updatebills(bill_row_id: $bill_row_id, item: $item) {
                    bill_row_id
                }
            }`,
            variables: {
                bill_row_id: bill.bill_row_id,
                item: {current_row_ind: false, upd_ts: new Date()}
            } 
        };
    
        fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(gql)
        });
    });
}

export {GetUserHomeData, GetBillsByUserID, UpdatePaidBills};