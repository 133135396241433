import "./App.css";

import { Route, useHistory } from "react-router-dom";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import oktaAuth from "./utils/oktaAuth";
import Home from "./Home";

function AppWithRouterAccess() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {/* This is the home page */}
        <Route path="/" component={Home} />

        {/* The call back is the route that is called after okta is done authenticating */}
        <Route path="/login/callback" component={LoginCallback} />
    </Security>
  );
}

export default AppWithRouterAccess;
