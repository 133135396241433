import { Button } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { UpdatePaidBills } from "../../utils/queries";

function CheckoutReturn() {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');

      fetch(`${window.location.origin}/api/session-status?session_id=${sessionId}`)
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        })
        .then((data) => {
            setStatus(data.status);
            setCustomerEmail(data.customer_email);
        })
        .catch((e) => {
            e.json().then((e) => {const error = e.error; alert(error.message); console.error(error.message); console.error(error.stack);});
        });
    }, []);

    // Use this effect so our DB doesnt get spammed
    // Comment this for testing so bills dont update
    useEffect(() => {
        if (status === 'complete') {
            UpdatePaidBills(JSON.parse(localStorage.getItem('cartData')));
        }
      }, [status]);
  
    if (status === 'open') {
      return (<Redirect to='bill_tracker/checkout'/>)
    } else if (status === 'complete') {
        return (
            <>
                <p>
                    We appreciate your business! A confirmation email will be sent to {customerEmail}.
                    If you have any questions, please email <a href="mailto:patmamons@gmail.com">patmamons@gmail.com</a>.
                </p>
                <Link to="/"><Button variant="primary">Home</Button></Link>
            </>
        )
    } 
    
    // This NEEDS to return null DO NOT CHANGE
    return null;
}

export default CheckoutReturn;