import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function render404Error () {
    return (
        <>
            <div>404, Page does not exist!</div>
            <div></div>
            <Link to="/"><Button variant="primary">Home</Button></Link>
        </>
    )
}

export default render404Error;