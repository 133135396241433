function login(){
    localStorage.clear()
    return (
        <div>
            <h1 className="cover-heading">Welcome to powerserv home page!</h1>
            <p>Please login using login button above</p>
        </div>
    );
}

export default login;