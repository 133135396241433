import React, { useState, useEffect } from "react";
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js';

function CheckoutForm(props) {
    const cart = props.cart;
    const stripePromise = props.stripePromise;
    const [clientSecret, setClientSecret] = useState('');
    
    localStorage.setItem('cartData', JSON.stringify(cart));
    
    useEffect(() => {
        // Create a Checkout Session as soon as the page loads
        fetch(`${window.location.origin}/api/create-checkout-session`, {
            method: "POST",
            body: JSON.stringify({cart: cart})
        }).then((res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        })
        .then((data) => setClientSecret(data.clientSecret))
        .catch((e) => {
            e.json().then((e) => {const error = e.error; alert(error.message); console.error(error.message); console.error(error.stack);});
        });
    }, []);
  
    return (
        <div id="checkout">
            {clientSecret && (
                <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret}}>
                    <EmbeddedCheckout/>
                </EmbeddedCheckoutProvider>
            )}
      </div>
    )
}

export default CheckoutForm;