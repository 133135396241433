function capitalizeStr(str) {
    return str.split(" ").map((word) => word[0].toUpperCase() + word.substr(1) + ' ');
}

function roundDecimal(num, scale=2) {
    const scaler = 10 ** scale;    
    return (Math.round(num * scaler) / scaler)
}

export {capitalizeStr, roundDecimal};
